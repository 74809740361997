<div class="core-plugin">
    <h1>#hex_ pc tool for custom theming</h1>
    <p>For custom theming on OneUI 3.1 and above, hex needs custom theme configuration via ADB and this #hex_ web adb tool can do that automatically for you.</p>
    <div class="core-plugin__info">
      <div class="core-plugin__info-header">Use Latest Chrome/Edge and Enable USB Debuggine on your phone</div>
      <div class="core-plugin__buttons-container" style="margin-top: 8px;">
        <a class="core-plugin__button green" href="hex-theming/pc-tool.html">Open Hex Theming Tool - WebADB</a>
      </div>
    </div>
    <div class="core-plugin__info">
        <div class="core-plugin__info-header">Enable USB Debugging</div>
        <p>1. Open Settings -> About phone -> Software Information</p>
        <p>2. Tap <span class="bold">Build Number</span> 7 times to enable <span class="bold">Developer Options</span></p>
        <p>3. Go back to main Settings page -> Click <span class="bold">Developer Options</span> (below About phone)</p>
        <p>4. Find <span class="bold">USB Debugging and turn on</span></p>
        <p>5. If you're using USB Debugging for the first time, you will get <span class="bold">Allow USB Debugging prompt</span> on your phone when you run this tool.</p>
      </div>
  </div>
  