<div *ngIf="isLoaded" class="privacy-policy">
    <h1 class="privacy-policy-hex-title">{{pluginName}}</h1>
    <h1>Privacy Policy</h1>
    <span>Last Updated: {{pluginDate}}</span>
  
    <p>
      This page is used to inform users of {{pluginName}} regarding our policies
      with the collection, use, and disclosure of Personal Information.
    </p>
    <p>
      If you choose to use our app, then we recommend you to read the following and agree.
    </p>
    <h3>Information Collection and Use</h3>
    <p>
      {{pluginName}} does not collect any personally identifiable information
      like location, contacts, messages or any files at all.
    </p>
    <p>
      {{pluginName}} uses third-party Google Play Services for license verification. You can read their Privacy Policy here - <a href="https://policies.google.com/privacy">Google Privacy Policies</a>.
    </p>
    <h3>Log Data</h3>
    <p>
      {{pluginName}} does not read, write or collect any kind of usage data and there's no analytics service involved. {{pluginName}} does not operate in the background after you close it completely.
    </p>
    <h3>Link to other sites</h3>
    <p>
      {{pluginName}} app and website may contain links to other sites like Play
      Store. If you click on a third-party link, you will be directed to that
      site. Note that these external sites are not operated by us. Therefore, we
      strongly advise you to review the Privacy Policy of these websites. We have
      no control over and assume no responsibility for the content, privacy
      policies, or practices of any third-party sites or services.
    </p>
    <h3>App Content</h3>
    <p>
      {{pluginName}} is targeted for 18+ and may unintentionally appeal to children.
    </p>
    <h3>Permission Required</h3>
    <div>
      <p class="privacy-policy-bold-header">Internet</p>
      <p>
        Required for Google Play Services to check the license during the app launch.
      </p>
    </div>
    <h3>Changes to this Privacy Policy</h3>
    <p>
      We may update our Privacy Policy from time to time. Thus, you are advised to
      review this page periodically for any changes. We will notify you of any
      changes by posting the new Privacy Policy on this page.
    </p>
  
    <h3>Contact Us</h3>
    <p>
      If you have any concerns, questions or suggestions about our Privacy Policy,
      do not hesitate to contact us at {{pluginSupport}}
    </p>
  
    <h5>This policy is written by {{pluginAuthor}},<br>Author of {{pluginName}}.</h5>
  </div>
  