import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppComponent } from '../app.component';
import { RequestService } from '../request.service';

@Component({
  selector: 'app-hex-plugin-privacy-policy',
  templateUrl: './hex-plugin-privacy-policy.component.html',
  styleUrls: ['./hex-plugin-privacy-policy.component.css']
})
export class HexPluginPrivacyPolicyComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  public pluginName: string = "Hex Plugin";
  public pluginDate: string = "Fri Dec 24 2021";
  public pluginAuthor: string = "SatySatsZB & envy";
  public pluginSupport: string = "hexinstallersupp@gmail.com";
  public isLoaded = false;
  constructor(private appComponent: AppComponent, private title: Title, private route: ActivatedRoute, private request: RequestService) {
    title.setTitle("Privacy Policy");
    appComponent.isPluginPrivacyPolicy = true;
    this.subscription = this.route.params.subscribe(params=>{
      const pluginID = params.pluginID;
      this.request.getAllPluginIDs().then(data=>{
        const plugin = data.rows.filter(x=>x.c[1].v===pluginID)[0];
        if(plugin){
          this.pluginDate = new Date(plugin.c[0]['f'] || this.pluginDate).toDateString()
          this.pluginName = plugin.c[2].v || this.pluginName;
          this.pluginAuthor = plugin.c[3].v || this.pluginAuthor;
          this.pluginSupport = plugin.c[4].v || this.pluginSupport;
          this.isLoaded = true;
        }
      });
    });
  }
  ngOnInit(): void {
  }
  ngOnDestroy(): void{
    this.subscription.unsubscribe();
  }

}
