import { Component, OnInit } from '@angular/core';
import { RequestService } from '../request.service';
import { DomSanitizer, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-hex-faqs',
  templateUrl: './hex-faqs.component.html',
  styleUrls: ['./hex-faqs.component.css']
})
export class HexFaqsComponent {
  faqs: {question: string, answer: string}[] = [];
  constructor(private title: Title, private requestService: RequestService, private domSanitizer: DomSanitizer) {
    title.setTitle("#hex_ - FAQs");
    this.requestService.getAllFAQs().then(response=>{
      response.rows.forEach(value => {
        this.faqs.push({
          question: value.c[0].v,
          answer: value.c[1].v
        });
    });
    });
  }

  getSanitizied(value: string){
    return this.domSanitizer.bypassSecurityTrustHtml(value);
  }

}
