import { Component, OnInit } from '@angular/core';
import { RequestService } from '../request.service';
import { Title } from '@angular/platform-browser';

export interface PluginData {
  title: string;
  themer: string;
  description: string;
  banner: string;
  playLink: string;
}

@Component({
  selector: 'app-hex-showcase',
  templateUrl: './hex-showcase.component.html',
  styleUrls: ['./hex-showcase.component.css']
})
export class HexShowcaseComponent{

  plugins: PluginData[] = [];
  constructor(private title: Title, private requestService: RequestService) {
    title.setTitle("#hex_ - Plugins");
    this.requestService.getAllHexPlugins().then(response=>{
      response.rows.forEach(value => {
          this.plugins.push({
            title: value.c[1].v,
            themer: value.c[2].v,
            description: value.c[3].v,
            banner: value.c[4].v,
            playLink: value.c[5].v
          });
      });
    });
  }
}
