<div class="hex">
  <a href="https://play.google.com/store/apps/details?id=project.vivid.hex.bodhi" target="_blank"><img class="hex__logo" src="../assets/playicon.png"></a>
  <div class="hex__content">
    <a href="https://play.google.com/store/apps/details?id=project.vivid.hex.bodhi" target="_blank"><div class="hex__title">#hex_ installer</div></a>
    <div class="hex__description">A custom Theme Engine for Samsung OneUI that lets you create customizable themes with high granular controls.</div>
    <div class="hex__author">SatySatsZB & envy_</div>
  </div>
</div>
<div class="hex-plugins-header">UI Plugins</div>
<app-plugin-card *ngFor="let plugin of plugins" [plugin]="plugin"></app-plugin-card>
