import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private httpClient: HttpClient) {
  }

  public getAllHexPlugins(): Promise<SheetsResponse> {
    return this.httpClient.get('https://docs.google.com/spreadsheets/d/1pNXf0DkGhqIt6r90URW4QxK4JkVVRbbY1vzBxJTQTI0/gviz/tq?tqx=out:JSON', {
      observe: 'response',
      responseType: 'text',
    }).toPromise().then(this.parseSheetsResponse);
  }

  public getAllFAQs(): Promise<SheetsResponse>{
    return this.httpClient.get('https://docs.google.com/spreadsheets/d/1KCbm7Rx5gH-iagfrhrZsOKk4AY7ZfyA1EVKhsrKWNHo/gviz/tq?tqx=out:JSON', {
      observe: 'response',
      responseType: 'text'
    }).toPromise().then(this.parseSheetsResponse);
  }

  public getAllPluginIDs(): Promise<SheetsResponse>{
    return this.httpClient.get('https://docs.google.com/spreadsheets/d/1z6TQ89yBpuDF-DWG4VwckblCnuB7V2BrbUSSfKxZY2Q/gviz/tq?tqx=out:JSON', {
      observe: 'response',
      responseType: 'text'
    }).toPromise().then(this.parseSheetsResponse);
  }



  private parseSheetsResponse(response: HttpResponse<string>): Promise<SheetsResponse>{
    const result = response.body;
    const start = result.indexOf("{", result.indexOf("{") + 1);
    const end = result.lastIndexOf("}");
    const data = result.substring(start, end);
    try{
      return Promise.resolve(JSON.parse(data));
    }catch (e) {
      return Promise.reject();
    }

  }

}

interface SheetsResponse {
  rows: {c: {v: string}[]}[]
}
