import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hex-core-pc-tool',
  templateUrl: './hex-core-pc-tool.component.html',
  styleUrls: ['./hex-core-pc-tool.component.css']
})
export class HexCorePCToolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
