<div class="privacy-policy">
  <h1 class="privacy-policy-hex-title">#hex_ installer</h1>
  <h1>Privacy Policy</h1>
  <span>Last Updated: 6 June 2020</span>

  <p>
    This page is used to inform users of #hex_ installer regarding our policies
    with the collection, use, and disclosure of Personal Information.
  </p>
  <p>
    If you choose to use our app, then we recommend you to read the following and agree.
  </p>
  <h3>Information Collection and Use</h3>
  <p>
    #hex_ installer does not collect any personally identifiable information
    like location, contacts, messages or any files at all.
  </p>
  <p>
    #hex_ installer uses third-party Google Play Services for license verification and In-App Purchases. You can read their Privacy Policy here - <a href="https://policies.google.com/privacy">Google Privacy Policies</a>.
  </p>
  <h3>Log Data</h3>
  <p>
    #hex_ installer does not read, write or collect any kind of usage data and there's no analytics service involved. #hex_ installer does not operate in the background after you close it completely.
  </p>
  <h3>Link to other sites</h3>
  <p>
    #hex_ installer app and website may contain links to other sites like Play
    Store. If you click on a third-party link, you will be directed to that
    site. Note that these external sites are not operated by us. Therefore, we
    strongly advise you to review the Privacy Policy of these websites. We have
    no control over and assume no responsibility for the content, privacy
    policies, or practices of any third-party sites or services.
  </p>
  <h3>Plugins and Themes sharing</h3>
  <p>
    #hex_ installer can connect to this website to view the list of published
    plugins on Google Play and Frequently Asked Questions. #hexified, part of
    #hex_ installer, connects to our Google Docs and lists the themes shared by
    the users through Submit form. While submitting, there is no logging or authentication.
    The form contains fields for your theme, your display name, screenshots and hex project texts. Form data that you submit, will be displayed as it is, without any modification.
    The forms and sheets used, are of Google Docs and the screenshots that you're agreeing to share are uploaded to imgur. You can read imgur privacy policy here - <a href="https://imgur.com/privacy">imgur privacy policy</a>.
    You can disable #hexified_ component anytime in #hex_ installer's preferences page.
  </p>
  <h3>App Content</h3>
  <p>
    #hex_ installer is targeted for 18+ and may unintentionally appeal to children.
  </p>
  <h3>Permissions Required</h3>
  <p>
    #hex_ installer requests the following permissions to perform the basic
    functions provided.
  </p>

  <div>
    <p class="privacy-policy-bold-header">1. Read and Write Storage</p>
    <p>
      Required to write your theme files and projects that you create and edit
      and also to pick images for wallpaper and background customization.
    </p>
  </div>
  <div>
    <p class="privacy-policy-bold-header">2. Internet</p>
    <p>
      Required for Google Play Services to check the license during the app launch and also to connect
      to our aforementioned Google Docs while you're navigating through #hexified.
    </p>
  </div>
  <div>
    <p class="privacy-policy-bold-header">3. Request Install and Delete Packages</p>
    <p>
      Required to Install/Uninstall the theme packages that you create on your
      device. All the theme apks produced by #hex_ installer are safe and contain images
      and drawables to theme the apps as per the overlay guidelines and have no
      code to perform any function. The apks have
      <a href="https://developer.android.com/guide/topics/manifest/application-element#code">android:hasCode="false"</a>
      attribute to let the platform know that the apk has no privilege to
      execute any code at all. #hex_ installer always seeks your consent before
      installing your themes.
    </p>
  </div>
  <h3>Changes to this Privacy Policy</h3>
  <p>
    We may update our Privacy Policy from time to time. Thus, you are advised to
    review this page periodically for any changes. We will notify you of any
    changes by posting the new Privacy Policy on this page.
  </p>

  <h3>Contact Us</h3>
  <p>
    If you have any concerns, questions or suggestions about our Privacy Policy,
    do not hesitate to contact us at hexinstallersupp@gmail.com
  </p>

  <h5>This policy is written by SatySatsZB,<br>Author of #hex_ installer.</h5>
</div>
