import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HexPluginCardComponent } from './hex-plugin-card/hex-plugin-card.component';
import { HexFaqsComponent } from './hex-faqs/hex-faqs.component';
import { HexShowcaseComponent } from './hex-showcase/hex-showcase.component';
import { HexPrivacyPolicyComponent } from './hex-privacy-policy/hex-privacy-policy.component';
import { HexCorePluginComponent } from './hex-core-plugin/hex-core-plugin.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HexCorePCToolComponent } from './hex-core-pc-tool/hex-core-pc-tool.component';
import { HexPluginPrivacyPolicyComponent } from './hex-plugin-privacy-policy/hex-plugin-privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    HexPluginCardComponent,
    HexFaqsComponent,
    HexShowcaseComponent,
    HexPrivacyPolicyComponent,
    HexCorePluginComponent,
    HexCorePCToolComponent,
    HexPluginPrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
