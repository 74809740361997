import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnDestroy{
  public currentRoute = '';
  private $routerEventsSubscription: Subscription;
  public isNightModeEnabled = false;
  public isPluginPrivacyPolicy = false;
  constructor(private route: Router){
    this.$routerEventsSubscription = this.route.events.subscribe(data=>{
      if(data instanceof NavigationEnd){
        this.currentRoute = data.url;
      }
    });
  }

  public toggleNightMode(){
    this.isNightModeEnabled=!this.isNightModeEnabled;
    const body = document.body;
    if(this.isNightModeEnabled){
      body.classList.remove("day");
      body.classList.add("night");
    }else{
      body.classList.remove("night");
      body.classList.add("day");
    }
  }

  ngOnDestroy(){
    if(this.$routerEventsSubscription){
      this.$routerEventsSubscription.unsubscribe();
    }
  }
}
