<div class="plugin-card card">
  <img [src]="plugin.banner" class="plugin-card__banner"/>
  <div class="plugin-card__content">
    <div class="plugin-card__header">
      <div class="plugin-card__title">{{plugin.title}}</div>
      <div class="plugin-card__themer">{{plugin.themer}}</div>
    </div>
    <a href="{{plugin.playLink}}">
      <img class="plugin-card__link" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"/>
    </a>
  </div>
  <!--    <div class="plugin-card__description">{{plugin.description}}</div>-->
</div>
