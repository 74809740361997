<div class="core-plugin">
  <h1>#hex_ installer - night mode unblocker</h1>
  <p>OneUI based on Android Pie does not allow us to enable night mode, when a theme is applied. This hex installer's tiny plugin helps us to unblock that restriction without any special permission or root.</p>
  <p>This plugin is built upon older sdk level and it can't be made available on Google Play Store for the same. This plugin is hosted on our github repository for secure download.</p>
  <div class="core-plugin__buttons-container">
    <a class="core-plugin__button green" href="https://github.com/SatySatsZB/NightModeUnblocker/releases/download/v1.0.1/hex.night-mode.plugin.apk">Download Plugin</a>
    <a class="core-plugin__button blue" href="https://github.com/SatySatsZB/NightModeUnblocker">Git Repository</a>
  </div>
  <div class="core-plugin__info">
    <div class="core-plugin__info-header">Information</div>
    <p>Since this app is a plugin for #hex_ installer, it doesn't have any launchable activity. To uninstall this, click <span class="bold">Manage #hex_</span> by holding #hex_ app icon or navigate to <span class="bold">Settings->Apps->#hex_ night-mode</span> and click Uninstall.</p>
  </div>
</div>
