import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {RequestService} from './request.service';
import { HexFaqsComponent } from './hex-faqs/hex-faqs.component';
import { HexShowcaseComponent } from './hex-showcase/hex-showcase.component';
import { HexPrivacyPolicyComponent } from './hex-privacy-policy/hex-privacy-policy.component';
import { HexCorePluginComponent } from './hex-core-plugin/hex-core-plugin.component';
import { HexCorePCToolComponent } from './hex-core-pc-tool/hex-core-pc-tool.component';
import { HexPluginPrivacyPolicyComponent } from './hex-plugin-privacy-policy/hex-plugin-privacy-policy.component';


const routes: Routes = [{
  path: '',
  component: HexShowcaseComponent
}, {
  path: 'faqs',
  component: HexFaqsComponent
}, {
  path: 'privacy-policy',
  component: HexPrivacyPolicyComponent
}, {
  path: 'night-mode',
  component: HexCorePluginComponent
}, {
  path: 'web-setup',
  component: HexCorePCToolComponent
}, {
  path: ':pluginID/privacy-policy',
  component: HexPluginPrivacyPolicyComponent,
  
}];

@NgModule({
  imports: [RouterModule.forRoot(routes), HttpClientModule],
  exports: [RouterModule],
  providers: [RequestService]
})
export class AppRoutingModule { }
