import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hex-core-plugin',
  templateUrl: './hex-core-plugin.component.html',
  styleUrls: ['./hex-core-plugin.component.css']
})
export class HexCorePluginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
