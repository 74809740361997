import {Component, Input, OnInit} from '@angular/core';
import { PluginData } from '../hex-showcase/hex-showcase.component';

@Component({
  selector: 'app-plugin-card',
  templateUrl: './hex-plugin-card.component.html',
  styleUrls: ['./hex-plugin-card.component.css']
})
export class HexPluginCardComponent implements OnInit {

  @Input()
  public plugin: PluginData;

  constructor() { }

  ngOnInit(): void {
  }

}
